import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled from 'styled-components';
import { IconLoader } from '../icons';

interface LoaderProps {
    onLoaded: VoidFunction;
}

const Loader: React.FC<LoaderProps> = ({ onLoaded }) => {
    const [isMounted, setIsMounted] = useState(false);

    const animate = () => {
        const loader = anime.timeline({
            complete: () => onLoaded(),
        });

        loader.add({
            targets: '#logo path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 1000,
            delay: function (el: any, i: number) {
                return i * 60;
            },
        });
    };

    useEffect(() => {
        const timeout = setTimeout(() => setIsMounted(true), 10);
        animate();
        return () => clearTimeout(timeout);
    }, []);

    return (
        <StyledLoader className="loader" isMounted={isMounted}>
            <Helmet bodyAttributes={{ class: `hidden` }} />

            <div className="logo-wrapper">
                <IconLoader />
            </div>
        </StyledLoader>
    );
};

Loader.propTypes = {
    onLoaded: PropTypes.func.isRequired,
};

export { Loader };
export default Loader;

const StyledLoader: any = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-navy);
    z-index: 99;

    .logo-wrapper {
        width: max-content;
        max-width: 600px;
        transition: var(--transition);
        opacity: ${(props: any) => (props.isMounted ? 1 : 0)};
        svg {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            fill: none;
            user-select: none;
            #B {
                opacity: 0;
            }
        }
    }
`;
